h1 {
  color: #333;
}

h5 {
  font-size: 16px !important;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 30px;
  text-align: left;
}

.bold-text {
  font-weight: 700;
}

.adutStar {
  color: red;
}

.dropzone {
  width: 50%;
  margin: auto;
  border: 2px dashed grey;
  border-radius: 10px;
  padding: 40px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.2s ease;
}

.dropzone p {
  font-size: 18px;
  color: #003366;
}

.dropzone:hover {
  background-color: #e6f7ff;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controls label,
.controls select,
.controls button {
  margin: 10px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  /* background-color: #003366; */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background: #0056b3 !important;
}

.upload-image {
  width: 50px;
  margin-bottom: 2px;
  color: #003366;
}

.multi-select-container {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
}

.multi-select-container label {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: block;
}

.multi-select-dropdown {
  margin-bottom: 0px;
}

.showloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100%;
  background: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.error-text {
  color: red !important;
  margin-left: 320px !important;
}

button {
  background: #012ca0d4 !important;
}