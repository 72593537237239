.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 34px;
  background-color: #03045E;
}

.logo {
  position: absolute;
  top: 0px;
  left: 1px;
}

.logout-btn {
  position: absolute !important;
  top: -2px;
  right: 90px;
  background-color: #2325b3ba;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.logout-btn:hover {
  background-color: #0056b3;
}